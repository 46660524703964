import React from 'react';
import styles from './LooseItemLocations.module.scss';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { IconButton, Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import CancelIcon from '@material-ui/icons/Cancel';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { useLooseItemSelector } from 'hooks/LooseItems';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import { tableHeadConfig } from './tableHeadConfig';
import clsx from 'clsx';
import { getSum, pluck } from 'helpers/AppHelpers';

export default function LooseItemLocations() {
  const isMobile = useMobileViewport();
  const { control, clearErrors, getValues, setValue, formState } = useFormContext();
  const { fields, remove, append } = useFieldArray({ control, name: 'locations' });
  const { errors } = formState;

  const { locationList } = useLooseItemSelector();

  const isStatusAvailable = (row) => row?.status?.displayName === 'Available';
  const isRemovable = (row) => fields?.length !== 1 && isStatusAvailable(row);

  const handleRemoveClick = (index) => {
    clearErrors(`locations[${index}].byAheadAccount`);
    remove(index);
  };

  const handleAddRowClick = () => {
    const newRow = {
      location: locationList[0],
      quantity: 0,
      minimumQuantity: 0,
      status: { name: 'Available', displayName: 'Available' }
    };
    append(newRow);
  };

  const handleSublocationSelect = (name, value) => setValue(name, value);
  const handleLocationSelect = (name, value, index) => {
    setValue(`locations[${index}].sublocation`, null);
    setValue(name, value);
  };

  const getSublocations = (locationId) =>
    locationList?.find(({ id }) => id === locationId)?.sublocations || [];

  const getTotal = () => getSum(pluck(getValues('locations'), 'quantity'));
  const calculateTotal = () => setValue('quantity', getTotal());

  const forbiddenStatuses = ['InPicklist', 'InLeavelist', 'Rented', 'UsedInAssembly'];
  const isQuantityDisabled = (row) => forbiddenStatuses.includes(row?.status?.name);

  const enrichLocationOptions = (name) => {
    if (
      locationList?.length &&
      getValues(name)?.id &&
      !locationList.map(({ id }) => id).includes(getValues(name)?.id)
    ) {
      return locationList.concat(getValues(name)).sort((a, b) => a.id > b.id);
    } else return locationList;
  };

  return (
    <section className={styles.wrapper}>
      <TableContainer className={styles.table} component={Paper}>
        <Table>
          <TableHeadComponent config={tableHeadConfig} />
          <TableBody>
            {!!fields?.length &&
              fields.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    {isStatusAvailable(row) ? (
                      <FormSearchInput
                        name={`locations[${index}].location`}
                        options={enrichLocationOptions(`locations[${index}].location`)}
                        onSelect={handleLocationSelect}
                        classes={styles.inputs__location}
                        index={index}
                      />
                    ) : (
                      <span className={styles.disabled_cell}>{row?.location?.siteCode || ''}</span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {isStatusAvailable(row) ? (
                      <Controller
                        name={`locations[${index}].location`}
                        control={control}
                        render={() => (
                          <FormSearchInput
                            name={`locations[${index}].sublocation`}
                            options={
                              getSublocations(getValues(`locations[${index}].location`)?.id) || []
                            }
                            onSelect={handleSublocationSelect}
                            classes={styles.inputs__sublocation}
                          />
                        )}
                      />
                    ) : (
                      <span className={styles.disabled_cell}>{row?.sublocation?.name || ''}</span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormTextArea
                      name={`locations[${index}].notes`}
                      options={{
                        max: 500,
                        minRows: 1,
                        disableCounter: true,
                        focus: true,
                        disableError: true
                      }}
                      classes={styles.inputs__description}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.status?.displayName || ''}
                    {row?.status?.displayName === 'In Picklist' && ` (${row?.picklist?.name})`}
                    {row?.status?.displayName === 'In Leavelist' && ` (${row?.leavelist?.name})`}
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormInputText
                      name={`locations[${index}].minimumQuantity`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        disableError: true,
                        isDisabled: isQuantityDisabled(row)
                      }}
                      classes={styles.inputs__field}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(
                      errors?.locations?.[index]?.byAheadAccount?.message && styles.error
                    )}>
                    <FormInputText
                      name={`locations[${index}].byAheadAccount`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        disableError: true,
                        isDisabled: isQuantityDisabled(row)
                      }}
                      classes={styles.inputs__field}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormInputText
                      name={`locations[${index}].quantity`}
                      options={{
                        focus: true,
                        type: 'quantity',
                        disableError: true,
                        extraAction: calculateTotal,
                        isDisabled: isQuantityDisabled(row)
                      }}
                      classes={styles.inputs__field}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {isRemovable(row) && (
                      <IconButton onClick={() => handleRemoveClick(index)}>
                        <CancelIcon />
                      </IconButton>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <div className={styles.left}>
          <span>[1]Buy Ahead Account</span>
          <Button className={styles.left__button} onClick={handleAddRowClick}>
            <AddIcon />
            <span>Add</span>
          </Button>
        </div>
        {!isMobile && (
          <Controller
            name="quantity"
            render={({ field: { value } }) => <p className={styles.total}>Total: {value || 0}</p>}
          />
        )}
      </div>
    </section>
  );
}
