import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from '../common/Summary.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper,
  TableHeadComponent
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { StyledButton } from 'components/Buttons';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';

import { Table, TableBody } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useLeavelistActions, useLeavelistSelector } from 'hooks/Leavelist';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { formatDate, dateTimeFormat, getUserFullName } from 'helpers/AppHelpers';

import {
  LOCATION_DETAILED_PATH,
  LEAVELIST_DETAILED_PATH,
  LEAVELIST_EDIT_PATH
} from 'constants/routeConstants';
import { LEAVELIST_TABLE_PARAMETER } from 'constants/configTableConstants';

import {
  generateChips,
  generateInitialQuery,
  useSummaryParams,
  parseQuery,
  tableConfig,
  removeChip
} from '../common/helpers';

import FilterModal from '../common/Filter';

export function LeaveListSummary() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);

  const urlParams = useSummaryParams();

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);
  const [filterCriteria, setFilterCriteria] = useState({});

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter } = useLeavelistSelector();
  const { getLeavelistsAction, getFilterCriteriaAction, setFilterAction, clearStateAction } =
    useLeavelistActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const getLeavelists = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getLeavelistsAction(parseQuery(query)).then((tableData) => {
        setLeavelists(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getLeavelistsAction]
  );

  const setLeavelists = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      setFilterCriteria(criteria);
      const q = {
        tableParam: LEAVELIST_TABLE_PARAMETER,
        getTableLimit,
        isAllLimitEnabled,
        filter,
        ...urlParams
      };
      const resultFilter = generateInitialQuery(q);
      getLeavelists(resultFilter);
      setChips(generateChips(criteria, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getLeavelists(getChangedBySearchFilter(filter, value)),
    [filter, getLeavelists]
  );
  const sortTable = useCallback(
    (rowName) => getLeavelists(getChangedBySortFilter(filter, rowName)),
    [filter, getLeavelists]
  );
  const applyFilter = useCallback(
    (values) => {
      getLeavelists(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, filterCriteria, getLeavelists]
  );
  const changeLimit = useCallback(
    () => getLeavelists(getChangedByLimitFilter(filter, getTableLimit(LEAVELIST_TABLE_PARAMETER))),
    [filter, getTableLimit, getLeavelists]
  );
  const changePage = useCallback(
    (page, param) => getLeavelists(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getLeavelists]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip, filterCriteria)),
    [applyFilter, filters, filterCriteria]
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const createLeavelist = useCallback(() => history.push(LEAVELIST_EDIT_PATH), [history]);

  const MainButton = () => (
    <StyledButton label="Create leavelist" onClick={createLeavelist} classes={styles.main_button} />
  );

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Leavelist Summary">
          {!isMobile && <MainButton />}
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <MainButton />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.table_row}>
                  <StyledTableCell className={styles.nameCell}>
                    <StyledLink bold to={`${LEAVELIST_DETAILED_PATH}/${row.id}`}>
                      {row?.name || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.locationCell}>
                    <StyledLink
                      to={`${LOCATION_DETAILED_PATH}/${row.location.id}`}
                      isRedirectAvailable={!row.location?.deleted}>
                      {row?.location.siteCode || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.sublocationCell}>
                    {row.sublocation?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.projectCell}>
                    {row.project?.jobNumber || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.statusCell}>
                    {row.status?.displayName || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.pocNameCell}>
                    {row.pocName || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.userCell}>
                    {getUserFullName(row.createdByUser)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.dateCell}>
                    {formatDate(row?.createdAtUtc, dateTimeFormat)}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={LEAVELIST_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilter && (
        <FilterModal
          open={openFilter}
          setOpen={setOpenFilter}
          onApply={applyFilter}
          filter={filter}
          filterCriteria={filterCriteria}
        />
      )}
    </SummaryWrapper>
  );
}
